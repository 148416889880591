import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { graphql, Link } from "gatsby";
import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../../components/Grid';
import { MainProject, CardStaticImg, CardLink, CardContainer, CardBody } from '../../../../components/Card';
import { ButtonGhost } from '../../../../components/Button';
import { designTokens } from '../../../../components/Theme/designTokens';
import projects from '../../../../data/projects';
import { IconBox, IconCard } from '../../../../components/IconBox';
import { Box } from '../../../../components/Box';
import { Text } from '../../../../components/Text';
import { OutboundLink } from '../../../../components/StyledLink';
import { CallToAction } from '../../../../components/CallToAction';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdFlag from 'react-ionicons/lib/MdFlag';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Chargebacks911" mdxType="SEO" />
    <IntroProject projectName="Merchant App" projectDescription="An onboarding experience and management application for retailers and eCommerce" projectYear="2016 - 2019" projectImage="cb911-wizard-preview" subProject="Chargebacks911" subProjectLink="chargebacks911" mdxType="IntroProject" />
    <Section section="Background" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Background 📋</h5>
        <p>I was Designer #1 when I joined Chargebacks911 and it seemed as if design and product requirements were driven only by the business and client needs. Years of this process resulted in a broken user experience. After jumping into this process head first, I realized we needed to build a UX foundation that was rooted in user research, collaboration, and iterative prototyping.</p>
        <p>In order to understand the purpose of each of our solutions, I had to understand our internal methods, as well as the overall payments process - specifically credit card disputes. I jumped into a world rife with corporate acronyms, business rules, and convoluted logic.</p>
        <p><strong>Many retailers simply write-off falsely-protested charges as a cost of doing business.</strong> Even if they do try and show evidence, they may still find themselves asking:</p>
        <Box mdxType="Box">
          <ul>
            <li>Why is the chargeback process so complicated?</li>
            <li>How can I automate these repetitive tasks?</li>
            <li>How can I manage the disputes my financial team is handling?</li>
          </ul>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>What is a chargeback? 💳</h5>
        <p>It's difficult to explain how the entire process works, and if I tried to explain the entire thing on here, it would probably bore you to death - but when customers file a chargeback with their card issuer, this means that they are disputing a charge and request the card issuer for a refund.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.16666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAACE4AAAhOAFFljFgAAABx0lEQVQ4y41Uy27bMBDMl/beWy79g/5Iv6GH3FIURYECvQRoYscypCB1Uutl602JFMnpUg+blh8JAWIl7O5oODvUFYaltYb9rJSGVgp1zVBVFRhj3W7b9qDeRL1v1ld2UlFkjdgBs6ZFyWpwISAIiIsWUsoRBNY6BFRD0gsrfPzi4efdE5zFAp++BriZl5QRkA2nIDrWcRzD9/0OfCA0AaQis9z5Cz5/uMbt91+4f3jE7e85lo4LhNS82UBvt5Cc4+X1FZ7nEb44DTi+s4Lh7tsPJHEESR8p/CfobI3p2TYEHkWm5gzD6VDmqxTP/yJkJRty6kC3vMgRJXGHri8x7LSk7VfAA9VL3XPSu9jXFW2BsI5sIqcZ9imNoiiIQY52L/r+BBSzPEOapTvtLx7ZxOVyScNYkAebI0Djw9VqBcdxKF+/DzAIAvylplbw3SjsvLGNqbFMfh7QWCEMQ/jrNTZZiYxICjlorHrgJEm6Kb+p4RilNNooPCcafwIgKPuckYBzjtG7Vs8x4NQ642qE6hivifFo5BP1lxlOp+q6LmazmX0zLv8cpstuMJM0eplt/jZHNjrH8BxgnufdVdvSPU7T9IDZKcD/7wXizK0N6uAAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Chargeback Process",
          "title": "Chargeback Process",
          "src": "/static/5bd497114fe10c19eece091fc2fe8e88/d9199/chargeback-process.png",
          "srcSet": ["/static/5bd497114fe10c19eece091fc2fe8e88/8ff5a/chargeback-process.png 240w", "/static/5bd497114fe10c19eece091fc2fe8e88/e85cb/chargeback-process.png 480w", "/static/5bd497114fe10c19eece091fc2fe8e88/d9199/chargeback-process.png 960w", "/static/5bd497114fe10c19eece091fc2fe8e88/07a9c/chargeback-process.png 1440w", "/static/5bd497114fe10c19eece091fc2fe8e88/29114/chargeback-process.png 1920w", "/static/5bd497114fe10c19eece091fc2fe8e88/97a96/chargeback-process.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <p>A chargeback is a transaction that a card issuer returns to an acquirer as a financial liability and which, in turn, an acquirer may return to the merchant. There are three common reasons for chargeback including fraud, processing errors, other disputes (e.g. customer disputes). The card schemes define the chargeback process, rules and regulations.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Goals 🎯</h5>
        <p>Our goal was to create an application that would give them the ability to make that process easier and reduce a client's time spent managing their chargebacks. By using the client's aggregated data, we can build cases using retailer and transaction information.</p>
        <p>Using a lightweight, step-by-step application, clients could then choose from 1 of 3 service levels, the most in-depth of which would take all of the leg work away for them and automate the management process.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <CSSGrid mdxType="CSSGrid">
  <Col mdxType="Col">
    <Box center bg={({
          theme
        }) => theme.greenTransparent} marginBottom={designTokens.space[6]} paddingY={designTokens.space[6]} mdxType="Box">
      <Container mdxType="Container">
        <CSSGrid mdxType="CSSGrid">
          <Col mdxType="Col">
            <Text center mdxType="Text">Problem:</Text>
            <p className="lead">Chargeback cases cost retailers and merchants millions of dollars in time. How can we give merchants the ability to efficiently manage their cases and reduce operational overhead.</p>
          </Col>
        </CSSGrid>
      </Container>
    </Box>
  </Col>
    </CSSGrid>
    <Section section="Onboarding" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Onboarding 🏃‍♀️</h5>
        <p>One of the largest areas creating bottlenecks in our internal process was the gathering of client data and creating an account on behalf of the client. Our teams would go through rounds of back and forth with clients asking for third-party login credentials, retailer information, types of products sold, etc.</p>
        <p><strong>We simply needed to rethink this entire process.</strong></p>
        <p>After seeing our support teams' experience in manually inputting complex client information for setup, we realized we can gather more accurate data more easily by letting the client onboard themselves - thus came the onboarding wizard. We needed to collect pieces of data from the client, which included:</p>
        <Box mdxType="Box">
          <ul>
            <li>Types of product(s) sold</li>
            <li>Locations of operation</li>
            <li>Banking credentials</li>
            <li>Type of data collected</li>
          </ul>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsSAAALEgHS3X78AAACI0lEQVQoz21TTY+bMBTk/9+r/oQeeutpW61atZU23UObQxM1yUJIyleCDdjYgI2BdGxy6SYjCwxm3pv33uClZ4ssP0eJReoQJ4ldQHJFPMPtD4dDGIa4ekUYrrfb7Pdq4Hy4TL3Wfd9Pl/uYJnuCIEEQHI9Hr/ob+WFIgv3QtF3XnU4nQojWev56HIbBmFfkKIr2+73N3FVVxZgyxgwD2DmhSinse2OU1mVV1UIYd4oFgAzmbrezmQWvKSGSc6RteC2FGIFpwmUYx+EepJSMMSj3RNNIQoKfv749LdKnHxelZNOQPEcUCEaIu2Uj03q99nCry1IUhRWvtRNoNUMfNprSntJR68nRnCZLbtt2tVp5ndaiLHmW5ZQi0FzVwHi93VWLZ/Luff7xsctOnTEl54Txoq45tDWNy6yUqG2pFauQGWQbWcg/D58+vHmbPzzqJEXP4pycijKvWEZLJiXSuMxdJ4TQSqHVGLHN7ITpc14unlUUYw9OmGYJoWBmRdmb4UqGJYQbRu8wy7b8cWyTlBUlesCFjHNq1XYK+VutIduSKaWYtG3SKzLac7mcC9TS4wVl3Di31JhFxVCnrXm5XMLM6KF2xrySZ3tNk2y721EZO2rXMHgFPwY4MNbMx3BbP2g+fzW8Hp1Dx/+BEFfZIMPMFzfVeYw47IOw//J9EvJawg1Qwmaz8WBU3/fhNX9GEOBNGMdhfob39+7xFvgrXl5e/gHpIIamatHqDAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Onboarding Wizard",
          "title": "Onboarding Wizard",
          "src": "/static/e0e31d7231ca39b46fa865f20b9abd85/1cfc2/cb911-wizard-1.png",
          "srcSet": ["/static/e0e31d7231ca39b46fa865f20b9abd85/8ff5a/cb911-wizard-1.png 240w", "/static/e0e31d7231ca39b46fa865f20b9abd85/e85cb/cb911-wizard-1.png 480w", "/static/e0e31d7231ca39b46fa865f20b9abd85/1cfc2/cb911-wizard-1.png 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsSAAALEgHS3X78AAABt0lEQVQoz5VT246cMAzl//+iL/2PVtttVXU7D20fGK4jdkHLPQEShyTQEyijeRlpeoQig33sYzt4+btDUb6/vjnkef524BW4tY/XNE2TJMHpNUn6x/eLX78NY2ZdlVLLsqx3sLuQJIqiy+XidVkWJmkVxXaaxDRVRTFxbolWY1atlwPW2mvSLMviOHaVRdO0fU/zPFtjekZPX+T3H+QH9PmJgnAaxx5eoq7rxnE0yLiuYPq+78isrsuyFJyTUsS5KivohwoDY4tGI/uptTYbkIVzjra9QYixqqKXn5+ev+bP31YiLqam66DY4DHm3giCIPCklLxth6bpGFNERuuZaBgG9A/NMCBoPoBgfQzifD57UqmhbfuiKOsaPpTCZLq2IymRizE2z5qxHllARufXdWyVUYdz+HaH60nrEUVWdEAojwA06QwpEYbzhrx9Ak0I4cjaqMWOL6fhw0ddt2631t4u+ToCR4YYkNEJjL0yZOuqns/Rgm1v4XcHVtc1BIOzj2Tf5CNw5NPphPsMMSj732RMHD8GOBjPzreP4R+5qipkct3eXOCHKuOihmGIvyQ8ED8AXGyQ/wKjLI80rs8jPgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Onboarding Wizard",
          "title": "Onboarding Wizard",
          "src": "/static/dd5f7a343aae21acb9c51d60ce225dd8/1cfc2/cb911-wizard-2.png",
          "srcSet": ["/static/dd5f7a343aae21acb9c51d60ce225dd8/8ff5a/cb911-wizard-2.png 240w", "/static/dd5f7a343aae21acb9c51d60ce225dd8/e85cb/cb911-wizard-2.png 480w", "/static/dd5f7a343aae21acb9c51d60ce225dd8/1cfc2/cb911-wizard-2.png 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <p>In order to build the most beneficial cases for disputes, we needed to connect to the client's transaction resources (eg. CRM, processor, bank, etc.) as well as intelligently categorize the products they sold.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsSAAALEgHS3X78AAACMklEQVQoz3VTa4+bMBDM///eb/0zlXo5VerdVe01PSAECATCyzYYbB626Zj0klOvHQm0Rjve3Zllk+YWWZHHJ4s0TU+JBWL7XoNLfD2GYRgEAd6b+hD8dJzs+7NijTJmHMdlhTFmeYfLR/B934+iaEOPsRcEpX/Qfd9LWVXVMAxSynGcrtlmxfWIFv6QZV3Thg3zPE6T0po1FuATQoQQnHNKKdphjCJWSoGMhh3HsW23VVUWhWg5CIMQUoh5no3WICB1nidcqtcjgllZdF2HizD2hgvRFaX/8PBpe3/6vF2EaPuOMYYKoGo0g0fN7yXwPG+D8VpCurpmzHaL3LLlTnL2ssLNK+9cekXtluRA21QMJzHkcsSFr+Rx5ISwNC2qCj3rxYg0bV4cGUXd10e52xH4R0jT9RkGZI1YhbxVxgAYgjI6DCMqc9EXjEEo8fhtcr3edXl0FEnCg7BLkrlp/yavYgmIoo0RZVn7fp+cmON2IHh7cTgMWdZ4+/Z4nDp+I0/TBDIURmAVNgZ57Y/n3veL/b7wfXIISBjyKJru7qe7rT7nN3INnymFARcyKvd5Xu92fRzX+30bRbTIs7KkGHu3a369KP6m8tPTE9bNrItpyVoLKaG7NJqfs/Gcd1gXxuAthJreOGbJruvix0DlYQW6l0o12y/sw8fxXMATa7Sydl+WFPujr1aBXJalXYnXDHvtMC6ULUov/4cl+ysuf9kFURhGSRJlaXhE+G/EcYyqvwGmxYIfRwpaiwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Onboarding Wizard",
          "title": "Onboarding Wizard",
          "src": "/static/46cb067f10abce93b4e04241d442c1cc/1cfc2/cb911-wizard-3.png",
          "srcSet": ["/static/46cb067f10abce93b4e04241d442c1cc/8ff5a/cb911-wizard-3.png 240w", "/static/46cb067f10abce93b4e04241d442c1cc/e85cb/cb911-wizard-3.png 480w", "/static/46cb067f10abce93b4e04241d442c1cc/1cfc2/cb911-wizard-3.png 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsSAAALEgHS3X78AAACDUlEQVQoz2VT25KTQBTk/z/FKj9ASx980H3RaKnJSlgIsGSBcA+ZC3PDniGJWNt1ahhqps/0nD7jvZQW+al8Ph5fEGscV8gyGw5xHB8OB4xeE8U7f5//+q2GQc3zBAghpLRfKef/YYzBCH4YhkmSeG2aBlFUhaEmhBJS5XlTls3pVFdV0zScc2RRSq3JWZZdyaxtu6HnQtgtXS++fRebH1PXXZCLEsaw3mLUDksWCPZ938rGyXlR0vMZZ/DzyItCNY0WQt2Ak6WUagVCyDAMURR552EYyyr6uvn08OX4+WGm9DJNddtCc1Xj25ILmV8BZdntdh7j/Nx1Y9v2wzBxjsSy63h5mghB6GkynBulzL1mboSc7XbrsWkam6bPjmVdM0pxJ13VKkmxbhgzQti4FWx9siNzPnZdVxR5WVBKURMpBaWMEcIJYc4Co/RFSEyZlAOhxugbmbFxHGEJmIsrmKM+cBtGawMiDDJYgPtC6cmZfyWDALK9qmsM+IFiEivBGmPWsD7PzukbGZL7vlc2sVjIkACTsUlrcw+1TKwQ/Y+82WzQblaYA9YgG2QrFj+IVQqbRa3I+/0eD2O56tIPjNkpnwQ3RpoZDbIO3Bz5rlaBXFUVkoGvnSSJwroyXt59FD+3S0+/7pMgCLzQYXlly0N7enr64/vB46P/5m3w/kPynCbxIUniewBpmuLUv5/njMHSbcFyAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Onboarding Wizard",
          "title": "Onboarding Wizard",
          "src": "/static/b664d1d9d2de6f79024c4c3b3167ba9d/1cfc2/cb911-wizard-4.png",
          "srcSet": ["/static/b664d1d9d2de6f79024c4c3b3167ba9d/8ff5a/cb911-wizard-4.png 240w", "/static/b664d1d9d2de6f79024c4c3b3167ba9d/e85cb/cb911-wizard-4.png 480w", "/static/b664d1d9d2de6f79024c4c3b3167ba9d/1cfc2/cb911-wizard-4.png 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Efficient sorting and filtering 🗃</h5>
        <p>Many clients face a tsunami of transactions - so we began conducting user research and planning what we could deliver in the necessary timeline. Based on our findings, we architected a rule engine that could filter, sort, and flag through transactions to save time spent on manually searching.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsSAAALEgHS3X78AAABh0lEQVQoz22Si3KbMBBF/f9/1X5BOk7jZiCd2IiXkJAEktCDRy9gHE+md0AsSIer3dUpu90YpYSQpiiUXKXukhrqN3Ud7iNYhRlK6SnPc1UU55cXlqbzOMZxnOdZ9pry1jrvQ5ymeV4WXMtjXD8ssDmRPHdSCs6GGEOM3vtpmtJb/evG3qhOuEnb4fNa/eXmA8Fn+dHapLVZF8QOW85ZXVtrYwg7LC5/VFka73tttBmMkGYNbC8kRm0H54N8wFopH+4asXnnkBhKgKS6prFpai4X1zSkLKuqMhrLlRDihFI5g79peK6o94BD1+luLU5eFD1jLkns7zfP2A5jj5jc4CwDFJHwk3PYCovf7/CQJHfnoqiQjjGAt20Tcvd8huG6OcNohd/fzesr4Ar9qWv06XD+HzyGMChllWqFGPo+ch4ojcbwtoUh1jvnVucsyxB98ch5mvAqf/w05/Pe1uXQ3uGnPhMCJ7RnPIT4G/NNX/D1emWMcbT6EGJM4AyITfvzMe4BDi/K/g8ixqj+S9i9JAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Onboarding Wizard",
          "title": "Onboarding Wizard",
          "src": "/static/9c0eaf2ef1ea9018c8661dddd4520a61/d9199/cb911-wizard-8.png",
          "srcSet": ["/static/9c0eaf2ef1ea9018c8661dddd4520a61/8ff5a/cb911-wizard-8.png 240w", "/static/9c0eaf2ef1ea9018c8661dddd4520a61/e85cb/cb911-wizard-8.png 480w", "/static/9c0eaf2ef1ea9018c8661dddd4520a61/d9199/cb911-wizard-8.png 960w", "/static/9c0eaf2ef1ea9018c8661dddd4520a61/07a9c/cb911-wizard-8.png 1440w", "/static/9c0eaf2ef1ea9018c8661dddd4520a61/0d390/cb911-wizard-8.png 1472w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsSAAALEgHS3X78AAABvklEQVQoz41Ty27bMBDU/39IT/2KHIL04hzaHuxGD8uqFEuRRFrk8iFR7pB0Xk0CeEAQJLSzsztcJfXRo2nbw1+Puq6xV1X1skdUAfG83+/zPC+KIunz4vd21/z8tXC+rKu18/lrrOuKHQJpmiJFMpTlnyxrH1InpSBqmoYkOefDPlueDPELWQ/DyJme59k5ItU99UrhtszvsMQFgAzadrv1ZUP58fEoGJNExE9iEgiDgvsPywU4SNQohFdmXcePbba5v7n7Ud/enYlQfNu2xpqobn1N7mP/WZYlSuvTMIp+YJwbY5aQmDGmtbbWGqUU55pIG42vL3WAjLYTZczUD7xuur5XBKucNQYdIAvMcWhUSqs1wQkdvViibUEZ5GEYDoeqrklKT7aWSHoH13XBRQgbmJCGZVhvyFpPpxM0hZSxbBQrwxkRuM7ThEokSXQQPXslI2iaJh8Eb6wNZOuLVMpW9dw9OWMXb5sNr7mGEXgmj+MIeyInWuLTSakYm759Vze3cbI+d3uz2WBikAycqHy+Dp682+3wY4ATHPH818HAOTzyR1yeCuSu66I3Ls709cppAAY1f0ZxBcqyhOo/s6eRzNUBDvEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Onboarding Wizard",
          "title": "Onboarding Wizard",
          "src": "/static/b9c4eed7299a460e32e9ebc4c5dbb261/1cfc2/cb911-wizard-5.png",
          "srcSet": ["/static/b9c4eed7299a460e32e9ebc4c5dbb261/8ff5a/cb911-wizard-5.png 240w", "/static/b9c4eed7299a460e32e9ebc4c5dbb261/e85cb/cb911-wizard-5.png 480w", "/static/b9c4eed7299a460e32e9ebc4c5dbb261/1cfc2/cb911-wizard-5.png 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Using visuals 🎨</h5>
        <p>As part of the onboarding wizard, I created visual assets and a lightweight icon set to help illustrate selections and actions. I also included assets to help convey empty states, success states, product tiers, etc. Since we built the onboarding wizard to be white-labeled and themed for third-party partners, we made sure the visuals could be themed by utilizing the partner's brand palette.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsSAAALEgHS3X78AAABJUlEQVQoz31Sy27CMBDk//+DX+DEFXHnEHqhVCIKEDf4Ea+f600dTFKo2q5ka7We2RntejG8BhExxvq+T0LozSZZO/wdC3JWN83n+Sy32wGRhgEAMCeIgTEK4T8yAgSlvLVwOFCMmSw4N8YkY+zxGLuu+Cmm6J58k/PxIfCuo8m2NiZkQcTMTAAzOduRUua7wEZyxgVE8D63wBhHqFIgRNZHpahU7hFiNM7FQp6VkbGwf4s3XhQS521dN3WNxgyTUdX3l9PpfHhvL9cs9LAdQfdVlafVrdcUxirl9tYmokKebYvdrl4u3e02V+7K3j9KUyTnoKp+zNZp3bUtpfQysNzyY7XyWuP0IDmXTZOecL+vatTxPj4pWwB2vQopoYz6+QO9ruoL/cpGSoh0McQAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Illustrations in the wizard",
          "title": "Illustrations in the wizard",
          "src": "/static/a7f2dfda38359f0c0124d4181fce0aaa/d9199/cp-illust-1-8.png",
          "srcSet": ["/static/a7f2dfda38359f0c0124d4181fce0aaa/8ff5a/cp-illust-1-8.png 240w", "/static/a7f2dfda38359f0c0124d4181fce0aaa/e85cb/cp-illust-1-8.png 480w", "/static/a7f2dfda38359f0c0124d4181fce0aaa/d9199/cp-illust-1-8.png 960w", "/static/a7f2dfda38359f0c0124d4181fce0aaa/2cefc/cp-illust-1-8.png 1400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "95.83333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB8ElEQVQ4y41UYU/cMAzl//8nxseNcRJjGoKJD0xCcONux/XapmmTtN57TtL1TmshUhrHdZ79YjtngjEMAz+Cr/TeiS8K6fseM0ioKpVp4w/QO6f77uVFQttKPq8YGGenCl+WUn75DGAvwVqpb671IP+b+zv9T8D6+434qlwAjIIEGNsURQhBbNNIj5WyMUZ8kpsGMpzOAmbKgZHhIEEUCNFF+phdF/V0lhxFGPk/IAcB7K/HCIAD7dPTCN5uNlEGg+LiXO/6Q5RbUqacaIYUler7QUHNfi8eES9S1ghh5A4HpT6lzL1HxlXPfbrnDDSfZYA1d7cK5HFP5vYHnLS6r1Yr8XWtbNx2o5WQmc0C0rNlaSA6D9kgKp+iskwYEwHbDkwoz1PmSkDSRP3lpLj9m0bCvdv9Gal63u2HIgSl4tN5BMTh8uulHua+urqMhQ1H9bdrpf9+UljMCUCznAC4b9iGSc5XsUwZa6aai1n7O+vzzH2/lOXxccBldyhsBYdsf97HbLKwHx7GIq9WV9pR7z4OAa+N2e3GSJvtVgGZ8RoyVyajQAe5xdcmZUuzOaHsUr/2mF0qG1J26X9mdgSY2ntUng73ey3d8/M/S7UbjspllnKWxwmdeX0Vs16PkUznFOwkwuMxNbZMDu5uzunU/i8ms9cO9fyc/gAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Checkbox illustrations",
          "title": "Checkbox illustrations",
          "src": "/static/a2695674c91b5c18aea0615cacc46c52/d9199/cp-checkbox-images.png",
          "srcSet": ["/static/a2695674c91b5c18aea0615cacc46c52/8ff5a/cp-checkbox-images.png 240w", "/static/a2695674c91b5c18aea0615cacc46c52/e85cb/cp-checkbox-images.png 480w", "/static/a2695674c91b5c18aea0615cacc46c52/d9199/cp-checkbox-images.png 960w", "/static/a2695674c91b5c18aea0615cacc46c52/07a9c/cp-checkbox-images.png 1440w", "/static/a2695674c91b5c18aea0615cacc46c52/29114/cp-checkbox-images.png 1920w", "/static/a2695674c91b5c18aea0615cacc46c52/c7443/cp-checkbox-images.png 3488w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Email Notifications 📨</h5>
        <p>We also needed notifications for a plethora of use cases:</p>
        <CSSGrid mdxType="CSSGrid">
          <Col col={1 / 2} media={{
                phone: 1
              }} mdxType="Col">
            <Box marginBottom={designTokens.space[3]} mdxType="Box">
              Task alerts
            </Box>
          </Col>
          <Col col={1 / 2} media={{
                phone: 1
              }} mdxType="Col">
            <Box marginBottom={designTokens.space[3]} mdxType="Box">
              Returning users
            </Box>
          </Col>
          <Col col={1 / 2} media={{
                phone: 1
              }} mdxType="Col">
            <Box marginBottom={designTokens.space[3]} mdxType="Box">
              Password notifications
            </Box>
          </Col>
          <Col col={1 / 2} media={{
                phone: 1
              }} mdxType="Col">
            <Box marginBottom={designTokens.space[3]} mdxType="Box">
              Upgrade prompts, etc.
            </Box>
          </Col>
        </CSSGrid>
        <p>By using <OutboundLink rel="noopener noreferrer" rel="noopener noreferrer" underline="true" href="https://tedgoas.github.io/Cerberus/" target="_blank" mdxType="OutboundLink">Ted Goas' Cerberus</OutboundLink>, we made sure all of our email notifications would work on every type of email client (Gmail, Outlook, Mail app, etc.).</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "93.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAAsSAAALEgHS3X78AAADbklEQVQ4y21T7UtTURy+GmZRfRC0TOdcWZAlRBQhavZBLbUo0FSKVUYRmfN1S6FyKhVByWZl3+qzQf9AEEVJWmCRXW0Tseamm9Pd7d67e8697uWe9bt3U4N6OFzO/Z3z/F6fQ7EsG1gFx3FW65Or167fNLR2t7T3m/v6zP29bR3NzS03mpo7jbccDgfGGK7xKqhQKBSLxWRZjhJZJnJd/fmt29Iys7T7NbqKytMnK08V7dqbmalJz9iZt2cfTdOSJEG8BPnFi5dNNw0dRlNXp6nnrtlmtxNCJFHEUZn5Ou79/JkTJYwgHhYEgWGYwDpYqr7hAkUlb9qSpt2WVny0cPzrNyWRSAS+0siI/OULbGKExP5BNBqlXC6XY845N+d0OJ0/f9ogpfhBJBJxOp2d7UaTqbvL1NV3x3zvwUPz7Z7uts62duONJsPTp0MU1KC6VnwjhICMBIFlOZkQmp5MTtmctCF1Y1JKfmb2kaJjhQUHtSmbN6RuoSiqrPwEBcXEyRANHAnBoAewuOj1LjldLpt92j6trCmb/cfExARNT4HFPm2z2WdnZylRFIG8srICA4AmQHCv2835fCjA8gxD1qolRBYECLBWM9SVIAMzyPN+xo8QFtkA53ajpSWJDRAVMEi4GgqHITVZhdJOSUqQISAcA5ljYTQSyAVLEhbFtciKEKLROC0OSDZBDofD8IU9MGEHgwLpoFCIqOz4lEVICiGB5+O6UsjBYBCrUFvN8TMziKbR1JTw/TtPT4JTKIdFyCdKDMbLCDNYDAqKaBRyr9lcWlpaVVlZXVV1qbHx9aHD4zuyxnR54wUHJy9fiYTDkCgzOjZz/4Hj2fNfA5bflkGJYcAoYkwNDAycq629qNdf0usNra1vikqmdXkTubt/na3xDb+KqmTRMRd4+477NMp9HPG//7DC81A9JL8uErWrpF+vP52xvT5vT022pruq2u3xLCwsLPp8Hp6b9zPzgYCH4xbAOD8PalBqhuyhU2rd2GA0HiouLi4vP15dfaahQZuTo83N1WRnH8jPrygrKy0p0Wo0Op0uPT29vq6O8vv9PhXwYpaXlyW1+UQdCYjearEOWhPr+dAQLKvF8mRw8PGjR6+Ghyl4lsFVgFQgPAwcmgy5wC/533taVxj/F+B2PH4cXq+XWYV3FWBfM/4BQI95NmOA3zoAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Email Notifications",
          "title": "Email Notifications",
          "src": "/static/fab20b02fc3579bccf64fdbb43b5c565/d9199/cb911-cp-1.png",
          "srcSet": ["/static/fab20b02fc3579bccf64fdbb43b5c565/8ff5a/cb911-cp-1.png 240w", "/static/fab20b02fc3579bccf64fdbb43b5c565/e85cb/cb911-cp-1.png 480w", "/static/fab20b02fc3579bccf64fdbb43b5c565/d9199/cb911-cp-1.png 960w", "/static/fab20b02fc3579bccf64fdbb43b5c565/07a9c/cb911-cp-1.png 1440w", "/static/fab20b02fc3579bccf64fdbb43b5c565/d61c2/cb911-cp-1.png 1800w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Merchant Portal 👩‍💻</h5>
        <p>After creating an account through the onboarding wizard, clients can begin managing their chargebacks and uploading their documents through the merchant portal. The merchant portal also gives users the ability to manage mutliple users, case reporting analytics, and fraudulent transaction alerts.</p>
        <p>Speaking with the support teams, we identified questions that often came up for new and old users, isolating pain points and assembling a timeline for what users experience as they progress through the app.</p>
        <Box mdxType="Box">
          <ul>
            <li>Why were users leaving?</li>
            <li>What elements of the interface were confusing or unclear?</li>
            <li>Were there any patterns that seemed too complex for the already complex process of payments?</li>
          </ul>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>User Journeys and Research 📚</h5>
        <p>After creating an account through the onboarding wizard, clients can begin managing their chargebacks and uploading their documents through the merchant portal. The merchant portal also gives users the ability to manage mutliple users, case reporting analytics, and fraudulent transaction alerts.</p>
        <p>Speaking with the support teams, we identified questions that often came up for new and old users, isolating pain points and assembling a timeline for what users experience as they progress through the app.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsSAAALEgHS3X78AAABV0lEQVQoz4WTa3uCMAxG+f//0Ec/oYBjXLpKC/TGDnSyoTijYCE5SfOmJl3XGWNCCNOe4RqGwVqrlLper2VZEr8GJ1x937+CcTnnWGit8zw/n891U3vv38MAaZpmWUZ91uMwsoCM6WZYCDGO4wMccwNQioJkZw0JtoHBnuG1MjCt3m43KSUwzW/guO21DbLwiCp6scvlQmWYqOsOvFbGgbZN09R1TTsANFwUBS7UfgPjM3MInzG666pqRfsAc9+HF0cI1syAVmmeFVkuxWxmMcr+A7ugZRjUIpiSXfclBHIZ+2ubQ/JUeZqcRa1PrCzpn1/6J+xwOJxOp+PxyAjY/w6sjO+tZ4eMp20F+pHILcaa/XNHV5AkpvwLuzAZPxHK+6qqCF1nIe8WT3gSg7awNf5HWCqsg2HBH+NjMfi9UVmjjObrrPPBx8M8vbB9weJIefkAh7vFx2+L76bOrkBs1gAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Userflows",
          "title": "Userflows",
          "src": "/static/88323658f86b28c4babdfedb0bdf8fdb/1cfc2/cb911-flow-1.png",
          "srcSet": ["/static/88323658f86b28c4babdfedb0bdf8fdb/8ff5a/cb911-flow-1.png 240w", "/static/88323658f86b28c4babdfedb0bdf8fdb/e85cb/cb911-flow-1.png 480w", "/static/88323658f86b28c4babdfedb0bdf8fdb/1cfc2/cb911-flow-1.png 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsSAAALEgHS3X78AAACIklEQVQoz3WTa27cIBSFvf9dZA9ZRP+1VdRKkyqdpJknAzbPCxeD6cFuR22lHiENZjjw3QdDgpgrUXO+xCilxEKMkQijC/NaayllhspcS21L2zQYY+BOHAM5/ELYs6zC3znP1lrvvVIK6zhocpq4H4cN3WyNcZwUh5Ci1nrdV4j0pPfWqsPhcL1en56enHM4+kTTxCFSDCEM06o8l5gycx7HEWbcoNRp/+ODlMdPHz9Lcf3+8vJttzNaByEaM6hw1oBrtTE1xebdHGmNlgC8LAArOWew9Phq7QOrSlVrYUYsA6iYOXjSow2BAEO/hYOwY9I6phS275RwExLcNjOYCRZHbowp8j+CBZs6yHotJgDjuxno+CiJi49zr9p/zACGv7XHx8fdbocJqjCAxwcqybU05RQCYk499I0cUWz33M1fvn693W5tS1hBA9RetnX0bli0aTEB8df4Q1tvLD19tWM/PDw8Pz+/v7+fTqfzqmCmmrn9rc0J1Mvl0u5mVAL1sM4BdV4lkrFM9x7ctDEj1Ti9A86zgxlNI4SwUkZjEahS2mjryCtvylJjyVzznRlN8bp/3b+9IZMlpYFzPh6PWctGY84sxChvo4n+RR7EpARZYfXlfAYgOMfktLc8TWiyNs8D+0SBcsZ76FVGb+JhIc/y1p8XA+IqvDX4C59nK+dW8fQwEMmgLopCTNvLZF67KG0V5tWBpXvZUUPAI7HX8xmTn9j4l8AoGhQJAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Userflows",
          "title": "Userflows",
          "src": "/static/665f77a889042b12a50174049325312b/1cfc2/cb911-flow-2.png",
          "srcSet": ["/static/665f77a889042b12a50174049325312b/8ff5a/cb911-flow-2.png 240w", "/static/665f77a889042b12a50174049325312b/e85cb/cb911-flow-2.png 480w", "/static/665f77a889042b12a50174049325312b/1cfc2/cb911-flow-2.png 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Why were users leaving?</p>
        </Box>
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>What elements of the interface were confusing or unclear?</p>
        </Box>
        <Box mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Were there any patterns that seemed too complex for the already complex process of payments?</p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Workflows for managing accounts 📁</h5>
        <p>We explored use cases and ideas from four different workflows to help challenge our assumptions, as well as push and pull different ideas for features. We looked at workflows that are conversation-focused, goal-focused, outcome-focused, and timebox-focused.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.41666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACK0lEQVQ4y6WUW2/aQBCF+etR+pCEqqry0h+Q0DZcbKpIeew/aJu2QBQhcccGm4AN3rWNDadntgZVlRpVCtLRrPfyzZlZ7NJ25sDttDC8/3rU6BmZ9e/fMPpx/zsWclo/IaxSPB7i7uYDrEYDt5+aaFoWbI5txmYh2/rj2bZhV6uoXl2hUa/DqtVQ4Viino5Reuo+4tZuIst30HGCl/wSd4rS/PEBF+dlzLtd9DoduL0eVq6LYLGA53nwqdVqhZBa+L6ZWy6XCILgqEUQYsW5xJmg5BH47u0l1GaDaL1GrBSSKMI2SZBlGbbbLfI8NzqMtdZYc/+G0irCF09j+LRG5hIYPLTx+dUp4jhGEIbIeGhLh9l8jjTW8On0ABJnEZPt93soJl5wzSRluxTnY3E4I/ANS5asvuebGNCpYpQk/X7fHJaD0+kUPsve7XZYc89wOETKSiShqU6AbqeNs7MLA3LpLGZUgwEXp4iZtcee6gI4Ho+PwJDVSLI0TZH+DTwnUNwcgGs2WksJnJtMJiaZAGezmSlbgNI/cfwsUG5TouKhmFCBO0wiQCnLK25c+im9FAMClGTRpgDOCCyzhwKSJsstK5aW8lISrTDiWNbyPDMAcbgvHIp70yIRnw3QabdwcnKKTaS4KTI3HRIasdnqsPkfkhdB/gUTx0EoFQkwHPRwWX7NV+ka9cp71Kj6dQW1/5T18cbozrKhJyOUsrmLnIpGA6gXSPObIB+HX7dn/RXJFyoXAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Service Manager",
          "title": "Service Manager",
          "src": "/static/106b85bac106543495f03f51c9f6fac1/d9199/cp-merchant-portal.png",
          "srcSet": ["/static/106b85bac106543495f03f51c9f6fac1/8ff5a/cp-merchant-portal.png 240w", "/static/106b85bac106543495f03f51c9f6fac1/e85cb/cp-merchant-portal.png 480w", "/static/106b85bac106543495f03f51c9f6fac1/d9199/cp-merchant-portal.png 960w", "/static/106b85bac106543495f03f51c9f6fac1/07a9c/cp-merchant-portal.png 1440w", "/static/106b85bac106543495f03f51c9f6fac1/5eb90/cp-merchant-portal.png 1612w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.41666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACEklEQVQ4y6WUe4/SQBTF+ebGmHXdsEaNfgENMUCBBf3TD7CaLBpj4vIGl2dbSjstfVAex3OHxTXqGh9NTubOdPq75952mukrD/V+F+eNS5w3G3j7h3rXau3jVlProtuBsDJd20LNKMCoVFCt1VA+O0OZsR4Pkvn1WqVaRckwkM/lUCyXYZRKyDEuMO44c2Q+jYaoVmvYbrZIkgT/c/XcBTIfvgxwnH0I2zQxnk5hz+dwPY+jA9O0YFuWniuO03Ybw3YHzngMZdtQSsGTexwd190DPxL47MlTRH6A2PexjWOkcYKLiQ/TjxAtA3h8wKcCar1aYRVFSFnNer3GZrNBGIY3wM/9Ht4cHSFlxuTqCslwiLVlYkunqyDQjgW2XC5h0WVMkDh2FgsNPFxLJukuHGTeE3jKkiXLglkkk6LTlE7SNMWUbZgTKsDRaISASRzHwWQywYp7fgLWCbx/kkXEBZcwj/IJjFm6PCAw+7pf4lBg0rfZbKaT/BJ4fHKqgbpXhEksvZG3LmCZiySWSn5ck32Kzr8DZpHwhpQjm8TZbrfTm2VNnNymgAopj0b0S5EePmAP4zj6tklcSNaIaxKHTBJG4S3aOxWHGljvdXHn7j1+Er5+m0q+K+9m9NXvddjjLNx9yZds7vGjx3j+Mo8X+SJV+CvlDB69ogHj1ev90Rv4CoNAocEz3fwXzW2tFmHyc/gK5nv8VOTdo5UAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Asset Manager",
          "title": "Asset Manager",
          "src": "/static/38e54a969c9fd6b4ce78665d543357f4/d9199/cp-asset-manager.png",
          "srcSet": ["/static/38e54a969c9fd6b4ce78665d543357f4/8ff5a/cp-asset-manager.png 240w", "/static/38e54a969c9fd6b4ce78665d543357f4/e85cb/cp-asset-manager.png 480w", "/static/38e54a969c9fd6b4ce78665d543357f4/d9199/cp-asset-manager.png 960w", "/static/38e54a969c9fd6b4ce78665d543357f4/07a9c/cp-asset-manager.png 1440w", "/static/38e54a969c9fd6b4ce78665d543357f4/5eb90/cp-asset-manager.png 1612w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>User Feedback 💬</h5>
        <Box bg={({
              theme
            }) => theme.green} color={({
              theme
            }) => theme.grey0} mdxType="Box">
          <p><i>"We needed an expert who could help stem the tide of chargeback losses while at the same reducing overhead. We absolutely love Chargeback911's user-friendly portal and extensive reporting capabilities.</i></p>
          <p style={{
                marginBottom: '0'
              }}><i>Plus the amount of data we have access to is phenomenal."</i></p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      